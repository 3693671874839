export default  {
    systemMenu: {
        label: 'System',
        user_access: 'system_menu',
        value: false,
        endpoints: [
            { name: 'user', route: 'record' },
            { name: 'registeractivity', route: 'record' },
            { name: 'access_group', endpoint: 'abm-access-group' },
            { name: 'operations', endpoint: 'operations' },
        ],
        icon: 'fa-user'
    },
    settingsMenu: {
        label: 'Settings',
        user_access: 'settings_menu',
        value: false,
        endpoints: [
            { name: 'settings', endpoint: 'settings' },
            { name: 'rules', endpoint: 'rules' },
            { name: 'routes', endpoint: 'routes' },
        ],
        icon: 'fa-cog'
    },
    reportsMenu: {
        label: 'Reports',
        user_access: 'reports_menu',
        value: false,
        endpoints: [
            { name: 'Recommendations', endpoint: 'rec-data' },
            { name: 'Recommendations Summary', endpoint: 'rec-summary' },
            { name: 'Lid Data', endpoint: 'lid-data' },
            { name: 'Lid Control', endpoint: 'lid-control' },
            { name: 'Health Status', endpoint: 'health-status' },
            { name: 'No Show PAX & No Show %', endpoint: 'tableau-report2' },
            { name: 'Markets', endpoint: 'tableau-report1' },
        ],
        icon: 'fa-file-alt'
    },
}
