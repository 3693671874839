<template>
    <div v-if="me" class="app-index">
      <div class="container">
        <div class="form-group row">
          <div class="col-sm-2">
            <input type="password" class="form-control inpc" v-model="password" name="password"  placeholder="Old Password" >
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-2">
            <input type="password" class="form-control inpc" v-model="newpassword" name="newpassword"  placeholder="New Password" >
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-2">
            <input type="password" class="form-control inpc" v-model="newpassword2" name="newpassword2"  placeholder="Re-Enter New Password" >
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-2">
            <button type="button" id="submit" class="btn btn-primary" @click="change"
            :disabled="!checkNewPassword || !password">Change Password</button>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-6">
            <div v-if="errors.length>0" class="alert alert-danger" role="alert">
                <div v-for="error of errors">
                    {{ error }}
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>



<script>
import { mapState } from 'vuex';
export default {
  name: 'change-password',
  data: function () {
    return {
        password: '',
        newpassword: '',
        newpassword2: '',
        errors: []
    }
  },
  computed: {
    ...mapState({
        me: state => state.main.me,
    }),
    checkNewPassword: function() {
        return (this.newpassword.length>0 && this.newpassword==this.newpassword2 && this.password!=this.newpassword);
    }
  },
  methods: {
    change: async function() {
        let data = {
            newpassword: this.newpassword,
            newpassword2: this.newpassword2,
            oldpassword: this.password
        }
        let res = await api.post('/change-password', data)
        if (res) {
            alert('Password modificado');
            this.$store.commit('setMe', res);
            this.$router.push('/');
        }
    },
    setProcessing: function(value) {
        this.processing = value;
    },
    addError: function  (msg) {
        app.addError(this, msg);
    },

  }
}
</script>

