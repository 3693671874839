<template>
    <div :class="cardView? 'card-container-report': 'container'">
        <div class="col-lg-15 my-3" v-if="isMounted" >
            <!--div class="col-md-0 row report-header" v-if="!hideFilters && !toggle">
                <div class="row col-md-0 report-header-collapsed">
                    <div class="col-12">
                        <button type="button" class="btn btn-primary toggle-button" @click="toggle = !toggle">
                            <font-awesome-icon icon="bars"/>
                        </button>
                    </div>
                    <div class="col-12" v-if="hideFilteredFields">
                        <button type="button" class="btn btn-primary toggle-button" @click="hideFilterColumns">
                            <font-awesome-icon icon="eye-slash"/>
                        </button>
                    </div>
                </div>
            </div-->

            <div class="card rounded-0" v-if="!hideFilters && toggle">
                <!--div class="col-2 report-filter report-toogle-col">
                    <button type="button" class="btn btn-primary toggle-button" @click="toggle = !toggle">
                        <font-awesome-icon icon="bars"/>
                    </button>
                </div>
                <div class="col-2 report-filter report-toogle-col" v-if="hideFilteredFields">
                    <button type="button" class="btn btn-primary toggle-button" @click="hideFilterColumns">
                        <font-awesome-icon icon="eye-slash"/>
                    </button>
                </div-->

                <div class="card-body pt-0">
                    <section class="bg-mix">
                        <div class="border-bottom rounded-0">
                            <div class="row">
                                <div v-for="field of filterFields" class="col-md-6 d-flex stat my-3"
                                    :class="getFieldColumns(field)" v-if="!field.hidden">
                                       <input-field
                                            v-if="!hideIf(field)"
                                            :field="field"
                                            :defValue.sync="current[field.name]"
                                            :invalid="invalid[field.name]"
                                            :fieldOptions="fieldOptions[field.name]"
                                            @afterEdit="updateCurrent(field.name)"
                                        ></input-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-2 col-md-6 d-flex">
                                    <div class="mx-left">
                                        <h6 class="text-muted asterisk">* {{tr('Required')}}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div class="card-body pt-0">
                    <button class="btn btn-sm btn-br btn-rule" @click="run">
                        <span v-if="!processing">{{tr('Run')}}</span>
                        <img class="processing-gif" src="@/img/spin.png" v-else>
                    </button>
                    <button class="btn btn-sm btn-bb btn-save" @click="download" :disabled="!dataList">{{tr('Download')}}</button>
                </div>
                <!--div class="col-12" v-for="b of buttons">
                    <button :class="b.buttonClass" @click="buttonCallback(b.callback, b.id)"
                        :disabled="!list || b.disabled || processingCallback[b.id]">
                            <span v-if="!processingCallback[b.id]">{{b.label}}</span>
                            <img class="processing-gif" src="@/img/spin.png" v-else>
                    </button>
                </div-->
            </div>
            <div class="col-lg-15 my-3 ">
                <div class="card rounded-0">
                    <div class="card-header" style="background-color: #fff">
                        <input type="search" :placeholder="tr('Search')"  v-if="!hideSearch" v-model="search"
                        aria-label="Search" class="form-control form-search">
                        <h6 class="font-weight-bold mb-2 mt-2">{{title}}</h6>
                    </div>

                    <div class="card-body pt-0">

                            <div :class="reportCols">
                                <div class="row">
                                    <h6 class="col-4">
                                        <i v-if="hiddenFilterColumnNames"> ({{hiddenFilterColumnNames}})</i>
                                    </h6>
                                    <div class="col-4">
                                        <span v-if="message" class="alert alert-warning" role="alert">
                                            {{message}}
                                        </span>
                                        <span v-else-if="errors.length>0" class="alert alert-danger" role="alert">
                                            {{errors.join('; ')}}
                                        </span>
                                    </div>
                                </div>
                                <div :class="reportViewClass? reportViewClass: 'report-view' " v-if="!processing && list">
                                    <div class="table-max-content" id="data-table">
                                        <v-client-table :data="list" :columns="columnsToShow" :options="options" @row-click="onRowClick">
                                          <template v-for="(r, rowNr) of slotsFields" slot-scope="props" :slot="r.name">
                                              <row-input-field
                                                :fieldOptions="getFilteredOptions(props.row, r)"
                                                :disabled="r.getDisabled && r.getDisabled(props.row)"
                                                :rowField="r"
                                                :arrayField="{name: 'report'}"
                                                :rowNr="props.index"
                                                :getId="true"
                                                :defValue.sync="props.row[r.name]"
                                                :row="props.row"
                                                @focus="focusOn"
                                                @afteredit="afterEdit(r.name, props.row)"
                                              ></row-input-field>
                                          </template>
                                          <template v-for="fieldName of notSlotFields" slot-scope="props" :slot="fieldName">
                                              <span
                                                :class="props.row.__total_row__? 'report-total-row': getFieldClass(props.row, fieldName)"
                                                @click="columnRowClick(props.row, fieldName)"
                                                @contextmenu.prevent="$refs.contextMenu.open($event, {fieldName: fieldName})"
                                                >
                                                {{applyTemplate(fieldName, props.row)}}
                                              </span>
                                          </template>
                                          <span
                                            data-toggle="tooltip" data-placement="top" :title="getHiddenColumns(props.row)"
                                            @contextmenu.prevent="$refs.showAll.open($event)"
                                            slot="__info__"
                                            slot-scope="props">
                                            <font-awesome-icon icon="info-circle"/>
                                          </span>

                                        </v-client-table>
                                    </div>
                                </div>
                            </div>
                            <div v-if="processing" class="col-10">
                                <img class="loading-gif" src="@/img/spin.png">
                            </div>

                    </div>
                </div>
            </div>

        </div>
        <vue-context ref="contextMenu">
            <ul slot-scope="child">
                <li @click="hideColumn($event.target.innerText, child.data.fieldName)">Ocultar</li>
            </ul>
        </vue-context>
        <vue-context ref="showAll">
            <ul slot-scope="child">
                <li @click="showAll">Mostrar Todos</li>
            </ul>
        </vue-context>

    </div>
</template>


<script>
import { mapState } from 'vuex';

import Vue from 'vue/dist/vue.esm';
import {ClientTable, Event} from 'vue-tables-2'
let theme;
Vue.use(ClientTable, [theme = 'bootstrap5']);
import VueContext from 'vue-context';
const inputField = importVueComp('components/tools', 'InputField');
const rowInputField = importVueComp('components/tools', 'RowInputField');

export default {
    name: 'report-window',
    props: {
        'endpoint': String,
        'fields': Array,
        'title': String,
        'headers': Object,
        'templates': Array,
        'columnsClasses': Object,
        'perPage': Number,
        'hideFilters': Boolean,
        'afterRun': Function,
        'beforeRender': Function,
        'runMounted': Boolean,
        'cardView': Boolean,
        'reportViewClass': String,
        'colsView': String,
        'notFilterable': Boolean,
        'slotsFields': Array,
        'callbacks': Object,
        'current': Object,
        'headerColumns': Array,
        'buttons': Array,
        'totalsOn': Array,
        'countOn': Array,
        'totalLabel': Array,
        'removeHeaders': Array,
        'hideFilteredFields': Boolean,
        'hideSearch': Boolean,
        'fieldClasses': Object,
        'columnsFormat': Object,
        'rowClick': Function,
        'columnClick': Object,
        'disableSort': Boolean,
        'allowChart': Boolean,
        'hideBlankColumns': Boolean,
        'updateReport': Boolean,
        'errorCallback': Function,
        'getFileName': Function
    },
    components: {
        'input-field': inputField,
        'row-input-field': rowInputField,
        ClientTable,
        'vue-context': VueContext,
    },
    data: function () {
        return {
            toggle: true,
            processing: false,
            processingCallback: {},
            fieldOptions: {},
            getInvalidClass: {},
            isMounted: false,
            search: null,
            dataList: null,
            message: null,
            errors: [],
            invalid: {},
            filterColumns: false,
            columnsNames: [],
            serverColumnsHeader: null,
            hiddenFilterColumnNames: null,
            hiddenColumns: [],
            lastValue: null,
            lastEdited: null,
            options: {
                filterable: false,
                perPageValues: [10, 20, 50, 100, 500, 1000],
                perPage: this.perPage? this.perPage: 10,
                headings: this.getHeadings(),
                texts: vueTools.vueTableText(),
                templates: this.getTemplates(),
                skin: 'table table-striped table-sm table-bordered table-bordered-hover',
                columnsClasses: this.columnsClasses? this.columnsClasses: {},
                customSorting: {},
            }
        }

    },
    computed:{
        ...mapState({
            me: state => state.main.me,
        }),
        columns () {
            return 12 / this.cols;
        },
        editorRows () {
            return [this.filterFields];
        },
        filterFields () {
            return tools.getAbmFields(this.fields);
        },
        columnsToShow () {
            if (!this.hideBlankColumns) return this.columnsNames;
            let res = [];
            for (let c of this.columnsNames) {
                if (this.removeHeaders && this.removeHeaders.indexOf(c)>-1) continue;
                let found = false;
                for (let row of this.list) {
                    if (row[c]) {
                        found = true;
                        break;
                    }
                }
                if (found) {
                    res.push(c)
                }
            }
            return res;
        },
        list () {
            let self = this;
            if (!this.dataList) return;
            if (this.search) {
                let values = this.search.split(' ')
                let res = _.filter(this.dataList, (r) => {
                    for (let value of values){
                        let found = false;
                        let re = new RegExp(tools.normalize(value), 'i')
                        for (let f of this.columnsNames) {
                            let displayValue = r[f];
                            if (self.templates) {
                                let template = _.find(self.templates, (t) => t.name==f);
                                if (template) {
                                    displayValue = template.callback(r, self.dataList);
                                }
                            }
                            if (displayValue){
                                let m = tools.normalize(displayValue).match(re);
                                if (m) found = true;
                            }
                        }
                        if (!found) return false;
                    }
                    return true;
                })
                return res;
            }
            return this.dataList
        },
        reportCols () {
            if (this.colsView) return this.colsView;
            if (this.cardView) return 'col-12';
            if (!this.cardView && !this.toggle) return 'col-12 report-toogle';
            return 'col-12';
        },
        notSlotFields () {
            let res = [];
            for (let c of this.columnsNames) {
                if (c == '__info__') continue;
                let f = _.find(this.slotsFields, (r) => r.name == c);
                if (!f) {
                    res.push(c);
                }
            }
            return res;
        }
    },
    asyncComputed: {
        async fieldOptionsTable() {
            /*let res =  await tools.calculateFieldOptions(this, false, this.slotsFields);
            return res;*/
        },
    },
    async mounted () {
        this.fieldOptions = await tools.calculateFieldOptions(this.filterFields);
        this.isMounted = true;
        if (this.runMounted) {
            await this.$nextTick();
            this.run()
        }
    },
    methods: {
        async run () {
            this.options.perPage = this.perPage? this.perPage: 10;
            this.dataList = null;
            let sep = ','
            if (this.me && this.me.DecimalSeparator) sep = this.me.DecimalSeparator;
            this.message = null;
            this.errors = [];
            let required = tools.checkFields(this, this.current);
            if (required) return;
            api.setProcessing(true);
            let filters = {};
            let current = _.cloneDeep(this.current);
            for (let i in this.current) {
                if (!this.current[i]) continue;
                filters[i] = this.current[i];
                if (Array.isArray(this.current[i])) {
                    filters[i] = this.current[i].join(',');
                }
            }
            let res = await api.get(this.endpoint, filters, this);
            let self = this;
            if (res) {
                this.dataList = res.rows
                if (this.dataList.length>0) {
                    if (this.dataList[0].id == undefined) {
                        for (let i=0; i<this.dataList.length; i++) {
                            this.dataList[i].id = i;
                        }
                    }
                }

                this.serverColumnsHeader = res.header;
                let cols = this.headerColumns? this.headerColumns: this.serverColumnsHeader
                this.columnsNames = _.cloneDeep(cols);
                if (this.removeHeaders) {
                    for (let item of this.removeHeaders) {
                        let index = this.columnsNames.indexOf(item);
                        if (index !== -1) {
                          this.columnsNames.splice(index, 1);
                        }
                    }
                }

                if (this.beforeRender) {
                    await this.beforeRender(this)
                }

                if (this.totalsOn || this.countOn) {
                    let row = {};
                    if (this.totalLabel) {
                        for (let n of this.totalLabel) {
                            row[n] = 'TOTAL';
                        }
                    }
                    if (this.totalsOn) {
                        for (let name of this.totalsOn) {
                            let template = _.find(this.templates, (t) => t.name==name);
                            let sum = this.dataList.reduce(function(s, f) {
                                let value = f[name];
                                if (template) {
                                    let vTemplate = template.callback(f);
                                    if (vTemplate) {
                                        value = parseFloat(vTemplate.replace(sep, ''));
                                    } else {
                                        value = null;
                                    }
                                }
                                if (value) return s + value;
                                return s;
                            }, 0);
                            row[name] = sum;
                            row.calculated = true;
                            row.__total_row__ = true;
                        }
                    }
                    if (this.countOn) {
                        for (let name of this.countOn) {
                            let count = this.dataList.length;
                            row[name] = 'Total: ' + count;
                            row.calculated = true;
                            row.__total_row__ = true;
                        }
                    }
                    this.dataList.push(row);
                    this.dataList = Object.assign([], this.dataList);

                }

                if (this.totalsOn || this.countOn) {
                    if (!this.disableSort) {
                        this.options.customSorting = this.getCustomSorting(this.columnsNames);
                        this.options = Object.assign({}, this.options);
                    }
                }
                if (this.disableSort) {
                    this.options.customSorting = this.disableSorting(this.columnsNames);
                }
                api.setProcessing(false);
                if (this.afterRun) {
                    this.afterRun(this)
                }
            }

        },
        download () {
        	api.setProcessing(true);
        	this.options.perPage = this.dataList.length;
        	setTimeout(() => {
        	    api.setProcessing(false);
        	}, 1);
            let i = setInterval(() => {
                let table = $('#data-table');
                if (table.length > 0) {
                    let fileName = 'data.xls'
                    if (this.getFileName) {
                        fileName = this.getFileName()
                    }
                    tools.downloadReport('data-table', fileName);
                    clearInterval(i);
                }
            }, 200);
        },
        downloadCSV () {
        	api.setProcessing(true);
        	this.options.perPage = this.dataList.length;
        	setTimeout(() => {
        	    api.setProcessing(false);
        	}, 1);
            let i = setInterval(() => {
                let table = $('#data-table');
                if (table.length > 0) {
                    let fileName = 'data.csv'
                    if (this.getFileName) {
                        fileName = this.getFileName()
                    }
                    tools.downloadReportCSV('data-table', fileName);
                    clearInterval(i);
                }
            }, 200);
        },
        hideIf (field) {
            if (field.hideIf && field.hideIf(this.current)) {
                return true;
            }
        },
        getHeadings () {
            let res = {};
            let self = this;
            for (let name in this.headers) {
                res[name] = self.headers[name];
            }
            res.__info__ = '';
            return res;
        },
        getTemplates () {
            let res = {};
            let self = this;
            if (!this.templates) return res;
            for (let field of this.templates) {
                res[field.name] = function(t, row) {
                        return field.callback(row, self.dataList);
                    }
            }
            return res;

        },
        updateCurrent (fieldName) {
            this.$emit('update:current', Object.assign({}, this.current));
            this.$emit('afterEdit', fieldName);
        },
        async afterEdit (rowFieldName, row) {
            let rowList = _.find(this.dataList, (r) => r.id == row.id);
            if (rowList) {
                rowList[rowFieldName] = row[rowFieldName];
            }
            if (this.lastValue) this.lastEdited = _.cloneDeep(this.lastValue);
            if (this.callbacks[rowFieldName]) {
                await this.callbacks[rowFieldName](rowFieldName, row, this);
            }
            if (this.lastValue) {
                this.lastValue.value = row[rowFieldName];
            }
            if (this.lastEdited) {
                this.lastEdited.value = row[rowFieldName];
            }
        },
        getFilteredOptions (row, field) {
            if (field.filterOptions) {
                return field.filterOptions(row, _.cloneDeep(this.fieldOptionsTable));
            }
            return this.fieldOptionsTable;
        },
        async buttonCallback (callback, id) {
            this.processingCallback[id] = true;
            this.processingCallback = Object.assign({}, this.processingCallback);
            await callback(this)
            this.processingCallback[id] = false;
            this.processingCallback = Object.assign({}, this.processingCallback);
        },
        getFieldColumns (field){
            if (!field.columns) return 'col-lg-2';
            return 'col-lg-' + field.columns;
        },
        addError (msg) {
            this.errors = [msg];
            this.message = null;
        },
        setProcessing (value) {
            api.setProcessing(value);
        },
        showAll () {
            this.filterColumns = false;
            let cols = this.headerColumns? this.headerColumns: this.serverColumnsHeader
            this.columnsNames = _.cloneDeep(cols);
            this.hiddenFilterColumnNames = null;
            this.hiddenColumns = [];
        },
        hideColumn (text, fieldName) {
            let i = this.columnsNames.indexOf(fieldName);
            if (i>-1) {
                this.columnsNames.splice(i, 1);
                this.hiddenColumns.push(fieldName);
            }
            if (this.hiddenColumns.length>0 && this.columnsNames.indexOf('__info__')==-1) {
                this.columnsNames.splice(0, 0, '__info__');
            }
        },
        hideFilterColumns () {
            this.filterColumns = !this.filterColumns;
            if (this.filterColumns) {
                let names = [];
                for (let fieldName in this.current) {
                    if (!this.current[fieldName]) continue;
                    let i = this.columnsNames.indexOf(fieldName);
                    if (i>-1) {
                        this.columnsNames.splice(i, 1);
                        let value = this.applyTemplate(fieldName, this.dataList[0]);
                        names.push(value)
                    }
                }
                this.columnsNames = Object.assign([], this.columnsNames);
                this.hiddenFilterColumnNames = names.join(', ');

            } else {
                let cols = this.headerColumns? this.headerColumns: this.serverColumnsHeader
                this.columnsNames = _.cloneDeep(cols);
                this.hiddenFilterColumnNames = null;
            }
        },
        applyTemplate (fieldName, row) {
            let value = row[fieldName];
            let template = _.find(this.templates, (t) => t.name==fieldName);
            if (template) {
                value = template.callback(row, this.dataList);
            }
            if (this.columnsFormat && this.columnsFormat[fieldName]) {
                value = this.columnsFormat[fieldName](value);
            }
            return value;
        },
        getHiddenColumns (row) {
            let names = [];
            for (let c of this.hiddenColumns) {
                let value = this.applyTemplate(c, row);
                names.push(value);
            }
            return names.join(', ');
        },
        disableSorting (fields) {
            let res = {}
            let self = this;
            for (let fieldName of fields) {
                res[fieldName] = function (ascending) {
                    return function(a, b){
                        return 0;
                        return 0;
                    }
                }
            }
            return res;
        },
        getCustomSorting (fields) {
            let res = {}
            let self = this;
            for (let fieldName of fields) {
                res[fieldName] = function (ascending) {
                    return function(a, b){
                        if (ascending) {
                            if (a.__total_row__) return 1;
                            if (a[fieldName] > b[fieldName]) return -1;
                            if (a[fieldName] < b[fieldName]) return 1;
                            return 0    ;
                        } else {
                            if (a.__total_row__) return 1;
                            if (b[fieldName] > a[fieldName]) return -1;
                            if (b[fieldName] < a[fieldName]) return 1;
                            return 0;
                        }

                    }
                }
            }
            return res;
        },
        getFieldClass (row, fieldName) {
            if (!this.fieldClasses) return '';
            if (!this.fieldClasses[fieldName]) return '';
            return this.fieldClasses[fieldName](row);
        },
        chart () {
        },
        focusOn (value, fieldName, rowFieldName, rowNr) {
            this.lastValue = {value: value, rowFieldName: rowFieldName, rowNr: rowNr-1};
        },
        handleError (error) {
            if (this.errorCallback) {
                this.errorCallback(this, error);
            }
        },
        onRowClick (data) {
            if (this.rowClick) {
                this.rowClick(data.row);
            }
        },
        columnRowClick (row, fieldName) {
            if (this.columnClick && this.columnClick[fieldName]) {
                this.columnClick[fieldName](row);
            }
        },
    },
    watch: {
        updateReport () {
            this.run();
        },
    }
}
</script>
